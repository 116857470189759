<template>
  <v-container>
    <row-header action="back" :msg="msgRowHeader"></row-header>

    <v-row dense>
      <v-col>
        <v-form
            ref="form"
            v-model="valid"
        >
          <v-card>
            <product-form-component
                :product_model="product_model"
                :cat_prod="cat_prod"
                :bank_prod="bank_prod"
                :status_list="status_list"
                update="1"
            />
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  :disabled="!valid"
                  color="success"
                  @click="saveData"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>
    </v-row>

    <v-overlay
        :absolute="absolute"
        :value="overlay"
    >
      <v-card color="white">
        <v-card-text>
          <overlay-img></overlay-img>
        </v-card-text>
      </v-card>
    </v-overlay>

  </v-container>
</template>

<script>
import RowHeader from "../../../components/content/RowHeader";
import OverlayImg from "../../../components/content/OverlayImg";
import ProductFormComponent from '../../../components/page/products/ProductFormComponent';
import { ProductData, StatusList } from '../../../store/services/ProductModel';
import { PutRequest, GetRequest } from "../../../store/services/users";

export default {
  components: {
    RowHeader,
    OverlayImg,
    ProductFormComponent,
  },
  data: () => ({
    msgRowHeader: {newUser: 'Back', newMsg: 'Update Product'},
    valid: true,
    absolute: false,
    overlay: false,
    product_model: new ProductData(),
    cat_prod: [],
    bank_prod: [],
    status_list: new StatusList(),
  }),
  async mounted() {
    console.log('category update view');
    if (this.$store.getters.isLoggedIn) {
      await this.getProductData();
      this.cat_prod = await this.getCatData();
      this.bank_prod = await this.getBankData();
    }
  },
  methods: {
    async getProductData() {
      await this.$store.dispatch('req_data')
      this.overlay = this.$store.getters.isLoading;
      try {
        const response = await GetRequest('products/' + this.$route.params._id);
        delete response.data._id;
        delete response.data.created_at;
        delete response.data.created_by;
        delete response.data.updated_at;
        delete response.data.updated_by;
        delete response.data.__v
        this.product_model = response.data;
        await this.$store.dispatch('req_success');
        this.overlay = this.$store.getters.isLoading;
      } catch (e) {
        await this.$store.dispatch('req_failed');
        this.overlay = this.$store.getters.isLoading;
        alert('Get user data failed ' + e.message);
      }
    },
    async getCatData() {
      await this.$store.dispatch('req_data');
      this.overlay = await this.$store.getters.isLoading;
      try{
        let response = await GetRequest('categories');
        await this.$store.dispatch('req_success');
        this.overlay = await this.$store.getters.isLoading;
        return response.data;
      }catch(err){
        await this.$store.dispatch('req_failed');
        this.overlay = await this.$store.getters.isLoading;
        alert('failed to get data : ' + err.message);
        return [];
      }
    },
    async getBankData() {
      await this.$store.dispatch('req_data');
      this.overlay = await this.$store.getters.isLoading;
      try{
        let response = await GetRequest('banks');
        await this.$store.dispatch('req_success');
        this.overlay = await this.$store.getters.isLoading;
        return response.data;
      }catch(err){
        await this.$store.dispatch('req_failed');
        this.overlay = await this.$store.getters.isLoading;
        alert('failed to get data : ' + err.message);
        return [];
      }
    },
    async saveData() {
      this.product_model.price = Number(this.product_model.price);
      await this.$store.dispatch('req_data')
      this.overlay = this.$store.getters.isLoading;
      try {
        const response = await PutRequest('products/' + this.$route.params._id, this.product_model);
        await this.$store.dispatch('req_success');
        this.overlay = this.$store.getters.isLoading;
        if (response.data && response.message === 'updated') {
          alert('success to updated data');
          await this.$router.push({name: 'ProductList'});
        } else {
          alert('failed to updated data');
        }
      } catch (e) {
        await this.$store.dispatch('req_failed');
        this.overlay = this.$store.getters.isLoading;
        alert('Get data failed ' + e.message);
      }
    },
  }
}
</script>

<style scoped>

</style>
